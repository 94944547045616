//https://docs.microsoft.com/en-us/onedrive/developer/rest-api/concepts/special-folders-appfolder

const MS_GRAPH_APP_ID = '851298c3-41c2-4b01-89b2-c4722b997925';
const MS_GRAPH_AUTH_SERVER =
  'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
const REDIRECT_URI = window.location.href.replace(window.location.hash, '');
const DATA_URL =
  'https://graph.microsoft.com/v1.0/me/drive/special/approot:/state.json:/content';

const isIframe = window !== window.parent;

export const initiateSignIn = (identity) => {
  console.log(identity)
  const prompt = identity ? 'none' : 'select_account'
  const hint = identity ? identity.email : ''
  const url = getAuthUrl(prompt, hint);
  window.location = url;
};

export const loadIdentity = () => {
  let id_token = new URLSearchParams(window.location.hash).get('id_token');
  if (!isIframe) {
    window.history.replaceState({ page: 'authenticated' }, '/', '/');
  }
  if (id_token) {
    localStorage.setItem('id_token', id_token);
  } else {
    id_token = localStorage.getItem('id_token');
  }

  if (id_token) {
    return JSON.parse(atob(id_token.split('.')[1]));
  }
};

export const requestToken = (username, callback) => {
  if (isIframe) {
    const access_token = new URLSearchParams(window.location.hash).get(
      '#access_token',
    );
    const expires_in = new URLSearchParams(window.location.hash).get(
      'expires_in',
    );
    const expires = new Date(+new Date() + Number(expires_in) * 1000);
    if (access_token && window.parent.handleAccessToken) {
      window.parent.handleAccessToken(access_token, expires);
    }
  } else {
    if (callback) {
      window.handleAccessToken = callback;
    }
    if (!window.authFrame) {
      window.authFrame = document.createElement('iframe');
      window.authFrame.src = getAuthUrl('none', username);
      window.authFrame.style =
        'border: none; display: none; width: 1px; height: 1px;';
      document.body.append(window.authFrame);
      window.authFrame = window.authFrame;
    }
  }
};

export const getAuthUrl = (prompt, hint) => {
  const nonce = Math.round(Math.random() * 10000000);
  let url = `${MS_GRAPH_AUTH_SERVER}?prompt=${prompt}&response_type=token+id_token&nonce=${nonce}&login_hint=${hint ||
    ''}&scope=profile+openid+https://graph.microsoft.com/Files.ReadWrite.AppFolder&client_id=${MS_GRAPH_APP_ID}&redirect_uri=${REDIRECT_URI}`;
  return url;
};

const serialize = state => {
  const starts = state.starts.map(s => ({ ...s, time: +s.time }));
  return JSON.stringify({ ...state, starts });
};

const deserialize = data => {
  const state = JSON.parse(data);
  const starts = (state && state.starts || []).map(s => ({
    ...s,
    time: new Date(s.time),
  }));
  return { ...state, starts };
};

export async function saveRemote(access_token, data) {
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: 'application/json',
  };
  const response = await fetch(DATA_URL, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    body: data,
    headers,
  });
  if (!response.ok) {
    console.log(response);
    throw new Error("Remote Sync Failed")
  }
  return response;
}

export async function loadRemote(access_token) {
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: 'application/json',
  };
  const response = await fetch(DATA_URL, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers,
  });
  if (!response.ok) {
    if (response.status === 404) {
      return '{}';
    }
    throw new Error("Remote Sync Failed")
  } else {
    const data = await response.text();
    return data
  }
}

export async function load(access_token) {
  if (!access_token) {
    return deserialize(localStorage.getItem('localSave'))
  } else {
    const data = await loadRemote(access_token)
    return deserialize(data) 
  }
  
}

export async function save(access_token, data) {
  const serialized = serialize(data)
  localStorage.setItem('localSave', serialized)
  if (access_token) {
    const response = await saveRemote(access_token, serialized);
    return response
  }
}