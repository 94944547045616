import React from "react";
import distanceInWords from "date-fns/distance_in_words";
import {
  View,
  Text,
  Button,
  Screen,
  Prompt,
  Menu,
  TransitionStateContext
} from "../../werksui";
import SyncStatus from "../components/SyncStatus";
import Timer from "../components/Timer";
// import ActivityList from '../components/ActivityList'
import bg from "../sun-mt-fuji-japan-landscape-407039.jpeg";

const slideDown = el => {
  if (!el || !el.firstChild || !el.firstChild.firstChild) {
    console.log("no element to slide");
    return;
  }
  const containerRect = el.getBoundingClientRect();
  const incoming = el.firstChild;
  const allItems = Array.from(el.children);
  const slideAmt = incoming.getBoundingClientRect().height;
  // el.style.transition = "";
  requestAnimationFrame(() => {
    allItems.forEach(c => {
      c.style.transition = "";
      c.style.transform = `translateY(${-slideAmt}px)`;
    });
    // incoming.style.animationDelay = '.25s'
    // incoming.classList.add("animated");
    // incoming.classList.add("fadeInLeft");
    // incoming.classList.add("faster");
    // el.style.transform = `translateY(${-slideAmt}px)`;
    requestAnimationFrame(() => {
      allItems.forEach(c => {
        c.style.transition = "transform .5s";
        c.style.transform = "";
      });
      // el.style.transition = "transform .5s";
      // el.style.transform = "";
    });
  });
  // }

  // play()
  // el.addEventListener('transitionend', () => {
  //   el.style.transition = '';
  // });
};
window.slideDown = slideDown;
const Tracker = props => {
  const {
    syncStatus,
    starts,
    tracking,
    activities,
    addActivity,
    startTracking,
    split
  } = props;
  const [promptText, prompt] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const transitionState = React.useContext(TransitionStateContext);
  const activityName = activity =>
    activities[activity] ? activities[activity].name : activity;
  const debugMessage = null; //`${transitionState}`;
  const activitiesConatiner = React.useRef(null);

  React.useLayoutEffect(() => slideDown(activitiesConatiner.current), [
    starts[0] && +starts[0].time
  ]);

  const renderMenu =
    menuOpen && (() => <Menu open={menuOpen} onSelect={toggleMenu} flex={1} />);

  return (
    <Screen
      title={
        `ctx.switch : track : ${syncStatus}` +
        (debugMessage ? ` : ${debugMessage}` : "")
      }
      header="Track"
      background={`url(${bg})`}
      toggleMenu={toggleMenu}
      renderMenu={renderMenu}
      split={split}
    >
      <View padding="1em">
        {(tracking && (
          <>
            <Text>{activityName(tracking.activity)}</Text>
            <Timer started={tracking.time} />
          </>
        )) || <Text>Select an activity to start tracking your time</Text>}
      </View>
      {promptText ? (
        <View padding="1em">
          <Prompt
            text="Add an activity"
            onSubmit={data => {
              addActivity(data);
              prompt(null);
            }}
            onCancel={() => prompt(null)}
          />
        </View>
      ) : (
        <View scroll padding="1em">
          {Object.entries(activities).map(([activity, { name }]) => (
            <Button
              onClick={() => startTracking(activity)}
              bright={tracking && activity == tracking.activity}
              key={activity}
              shadowSmall
              onLongPress={() => console.log('long press')}
            >
              <Text whiteSpace="nowrap">{name}</Text>
            </Button>
          ))}
          <Button
            text="+"
            primary
            circle
            shadowSmall
            onClick={() => prompt("activity")}
          />
        </View>
      )}
      <View
        column
        scroll
        flex={1}
        zIndex="0"
        style={{ overflowAnchor: "none" }}
        innerRef={activitiesConatiner}
      >
        {starts.slice(0, 20).map((start, n) => {
          const elapsed =
            starts[n - 1] && distanceInWords(start.time, starts[n - 1].time);
          return (
            <View
              key={+start.time}
              justifyContent="space-between"
              padding="1em"
              // className={
              //   transitionState !== TransitionStateContext.states.Hide
              //     ? "animated zoomIn faster"
              //     : null
              // }
              style={{
                animationDelay: `${
                  transitionState === TransitionStateContext.states.Show
                    ? Math.min(0.75 + n * 0.1, 2)
                    : 0
                }s`
              }}
            >
              <Text>{activityName(start.activity)}</Text>
              <Text>{elapsed || "In Progress"}</Text>
            </View>
          );
        })}
      </View>
    </Screen>
  );
};

export default Tracker;
