import React from 'react';
import {
  subDays,
  addDays,
  startOfYesterday,
  startOfDay,
  startOfToday,
} from 'date-fns';
import df from 'date-fns';
import { View, Box, Text, Button, Screen } from '../../werksui';
import bg from '../pexels-photo-755726.jpg';
import { list } from 'ui-box';

window.df = df;

const Measure = ({ name, value, legend }) => (
  <View column alignItems="center">
    <Text big>{name}</Text>
    <Text huge>{value}</Text>
    <Text small>{legend}</Text>
  </View>
);

const VerticalBarChart = ({ data }) => {
  const max = data.reduce((max, n) => Math.max(max, n[1]), 0);
  return (
    <View column>
      <Text big marginBottom="1em">
        Top activities hours spent
      </Text>
      <React.Fragment>
        {data
          .sort((a, b) => b[1] - a[1])
          .map(([label, value], n) => (
            <Box
              backgroundColor="rgba(255, 255, 255, .15)"
              padding="10px"
              width={`${(100 * value) / max}%`}
              margin="5px"
              key={n}
              transition="width 1s, height 1s;"
              display="block"
            >
              <Text whiteSpace="nowrap" display="inline">
                {label}:{' '}
                <Text bold bright display="inline" shadowSmall>
                  {value.toFixed(2)}
                </Text>
              </Text>
            </Box>
          ))}
      </React.Fragment>
    </View>
  );
};

const TopActivitiesByTime = ({ starts, activities }) => {
  const totals = sumTimeByActivity(starts);
  const data = Object.entries(totals).map(([key, val]) => [
    activities[key].name,
    val,
  ]);

  return <VerticalBarChart data={data} />;
};

export function* addMarkers(starts) {
  if (!starts[0]) {
    return;
  }
  let prev = starts[0];
  yield prev;
  for (let start of starts.slice(1)) {
    let marker = startOfDay(prev.time);
    if (startOfDay(start.time) < marker) {
      yield {
        time: marker,
        activity: start.activity,
      };
    }
    yield start;
    prev = start;
  }
  return;
}

const DATE_RANGE_FILTERS = [
  {
    name: 'Today',
    filter: (list, getDate) =>
      list.filter(item => getDate(item) >= startOfToday()),
  },
  {
    name: 'Yesterday',
    filter: (list, getDate) =>
      list.filter(
        item =>
          getDate(item) >= startOfYesterday() &&
          getDate(item) <= startOfToday(),
      ),
  },
  {
    name: 'Last 7 days',
    filter: (list, getDate) =>
      list.filter(item => getDate(item) >= subDays(startOfToday(), 7)),
  },
  {
    name: 'Last 30 days',
    filter: (list, getDate) =>
      list.filter(item => getDate(item) > subDays(startOfToday(), 30)),
  },
  {
    name: 'All Time',
    filter: (list, getDate) => list,
  },
];

export const sortStarts = (x, y) => +y.time - +x.time;

export const sumTimeByActivity = starts =>
  starts
    .map((start, n) => {
      const elapsed = starts[n - 1] ? +starts[n - 1].time - +start.time : 0;
      return {
        key: start.activity,
        ms: elapsed,
        hr: elapsed / 3600000,
      };
    })
    .reduce(
      (totals, activity) => ({
        ...totals,
        [activity.key]: (totals[activity.key] || 0) + activity.hr,
      }),
      {},
    );

const Analytics = props => {
  let { starts, activities, split } = props;
  const [dateRangeFilter, setDateRangeFilter] = React.useState(0);
  starts = Array.from(addMarkers(starts.sort(sortStarts)));
  starts = DATE_RANGE_FILTERS[dateRangeFilter].filter(starts, s => s.time);
  return (
    <Screen
      background={`url(${bg})`}
      title="ctx.switch : data"
      toggleMenu
      split={split}
      header="Data"
    >
      <View scroll padding="1em">
        {DATE_RANGE_FILTERS.map((filter, n) => (
          <Button
            onClick={() => setDateRangeFilter(n)}
            bright={n === dateRangeFilter}
            key={n}
            shadowSmall
          >
            <Text whiteSpace="nowrap">{filter.name}</Text>
          </Button>
        ))}
        <Button
          text="+"
          primary
          circle
          shadowSmall
          onClick={() => prompt('activity')}
        />
      </View>
      <View padding="1em" scroll column flex={1}>
        <TopActivitiesByTime starts={starts} activities={activities} />
      </View>
    </Screen>
  );
};

export default Analytics;
