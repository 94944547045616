import React from "react";
import { View, Box, Text, Button, Screen } from "../../werksui";
import { initiateSignIn, getAuthUrl } from "../storage";
import bg from "../beautiful-blur-close-up-delicate-259707.jpg";
import { clear } from "ui-box/dist/cache";

const Measure = ({ name, value, legend }) => (
  <View>
    <Text big>{name}</Text>
    <Text huge>total minutes</Text>
    <Text small muted>
      percent
    </Text>
  </View>
);

const Settings = props => {
  const {
    starts,
    activities,
    identity,
    access_token,
    split,
    toggleSetting,
    startTracking,
    toggles = [],
    clearToken
  } = props;
  const authUrl = getAuthUrl("none");
  const reset = () => {
    startTracking("reset")
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Screen
      background={`url(${bg})`}
      title="ctx.switch : config"
      header={`Config & options`}
      split={split}
    >
      <View margin="1em" column scroll>
        {identity && access_token ? (
          <Text big marginBottom={'1em'}>{`Hello ${identity.name}`}</Text>
        ) : (
          <Button
            primary
            text="Connect Account"
            onClick={() => initiateSignIn(identity)}
          ></Button>
        )}
        <Button text="Reset all" onClick={reset} />
        <Button text="Clear auth token" onClick={clearToken} />
        <Button
          text={`Turn ${toggles.includes("debug") && 'off' || 'on'} debug mode`}
          bright={toggles.includes("debug")}
          onClick={() => toggleSetting("debug")}
        />
        {toggles.includes("debug") && (
          <View column>
            <Text>{`access token: ${access_token}`}</Text>
            <Text>{`${JSON.stringify(identity)}`}</Text>
            <View>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 560 560"
                width="560"
                height="560"
              >
                <g fill="#61DAFB">
                  <svg x="160" y="20">
                    <rect width="100" height="100" x="0" y="0" />
                    <rect width="100" height="100" x="0" y="140" />
                    <rect width="100" height="100" x="0" y="280" />
                  </svg>
                  <svg x="300" y="160">
                    <rect width="100" height="100" x="0" y="0" />
                    <rect width="100" height="100" x="0" y="140" />
                    <rect width="100" height="100" x="0" y="280" />
                  </svg>
                </g>
              </svg>
            </View>
          </View>
        )}
      </View>
    </Screen>
  );
};

export default Settings;
