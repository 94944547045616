import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './timerdemo/App';
import registerServiceWorker from './registerServiceWorker';

const match = window.matchMedia('screen and (min-width: 1024px)');
const el = document.getElementById('root');
const render = (m) => ReactDOM.render(<App device={{size: m.matches ? 'big': 'small'}} />, el);
render(match)
match.addListener(render)
registerServiceWorker();
