import React from 'react';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import { Text } from '../../werksui';

const timer = started => {
  const now = new Date();
  const h = differenceInHours(now, started);
  const m = differenceInMinutes(now, started) % 60;
  const s = differenceInSeconds(now, started) % 60;
  const hh = h.toString().padStart(2, '0');
  const mm = m.toString().padStart(2, '0');
  const ss = s.toString().padStart(2, '0');
  return `${hh}:${mm}:${ss}`;
};

const Timer = ({ started }) => {
  const [ticks, tick] = React.useReducer(ticks => ticks + 1, 0);
  React.useEffect(() => {
    const ticker = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(ticker);
    };
  });

  return <Text>{timer(started)}</Text>;
};

export default Timer;
