import React, { Component, useContext } from "react";
import Box from "ui-box";
import iconMenu from "./menu.svg";
import "./werksui.css";

const sleep = async seconds => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000))

const theme = {
  shadowSmall: {
    boxShadow: "2px 2px 4px rgba(0,0,0,.45)"
  },
  shadowBig: {
    boxShadow: "0em 0em 2.5em rgba(0,0,0,.45)"
  }
};

export const hover = Wrapped => {
  return class Hover extends Component {
    constructor(props) {
      super(props);
      this.state = {
        hover: false,
        pressed: false,
        active: false
      };
    }
    render() {
      return (
        <Wrapped
          {...this.state}
          {...this.props}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false, active: false })}
          // onMouseDown={() => this.setState({ pressed: true, active: true })}
          // onMouseUp={() => this.setState({ pressed: false })}
        />
      );
    }
  };
};

const TransitionState = {
  Show: "In",
  Hide: "Out",
  Idle: "Idle"
};

export const TransitionStateContext = React.createContext(TransitionState.Idle);
TransitionStateContext.states = TransitionState;

export const View = ({
  children,
  column,
  scroll,
  loading,
  shadowSmall,
  ...rest
}) => (
  <Box
    fontSize="18px"
    display="flex"
    // flexWrap="nowrap"
    flexShrink={0}
    overflowX={!column && scroll ? "auto" : "hidden"}
    overflowY={column && scroll ? "scroll" : "hidden"}
    flexDirection={column ? "column" : "row"}
    boxShadow={shadowSmall ? theme.shadowSmall.boxShadow : "none"}
    {...rest}
  >
    {loading ? <Text>Loading</Text> : children}
  </Box>
);

export const BaseApp = ({ children }) => <div>{children}</div>;

export const ScreenSet = ({ children, nav, icon }) => {
  const transitionTimer = React.useRef(null);
  const [isTransitioning, toggleTransitioning] = React.useState(true);
  const toFront = (prev, item) => [item, ...prev.filter(x => x != item)];
  const [screenOrder, toggleScreen] = React.useReducer(
    toFront,
    children.map((_, n) => n)
  );
  const transitionToScreen = n => {
    if (screenOrder[0] !== n) {
      clearTimeout(transitionTimer.current);
      toggleScreen(n);
      toggleTransitioning(true);
    }
  };
  React.useEffect(() => {
    const timeout = setTimeout(() => toggleTransitioning(false), 2000);
    transitionTimer.current = timeout;
    return () => clearTimeout(timeout);
  });
  const zIndex = item => screenOrder.length - screenOrder.indexOf(item);
  const className = item =>
    screenOrder.indexOf(item) == 0 ? "animated fadeIn fast" : "";
  const transitionState = n =>
    n === screenOrder[0]
      ? isTransitioning
        ? TransitionState.Show
        : TransitionState.Idle
      : TransitionState.Hide;

  return (
    <div>
      {children.map((screen, n) => (
        <TransitionStateContext.Provider value={transitionState(n)} key={n}>
          <Box
            zIndex={zIndex(n)}
            position="absolute"
            className={className(n)}
            style={{ animationDelay: ".2s" }}
          >
            {screen}
          </Box>
        </TransitionStateContext.Provider>
      ))}
      <View
        position="fixed"
        width="100%"
        bottom={0}
        justifyContent="space-evenly"
        zIndex={10}
        backgroundColor="rgba(0,0,0,.75)"
      >
        {children.map((c, n) => (
          <Button
            transparent
            key={n}
            onClick={() => transitionToScreen(n)}
            animate
            padding={"0.66em"}
          >
            <Text color={n === screenOrder[0] && "rgba(113, 214, 19, .9)"}>
              {(nav && nav[n]) || `${n}`}
            </Text>
          </Button>
        ))}
      </View>
    </div>
  );
};

export const Screen = ({
  children,
  visible,
  split,
  title,
  header,
  subheader,
  renderMenu,
  toggleMenu,
  background
}) => {
  const transitionState = useContext(TransitionStateContext);
  const selectTransitionClass = (transIn, transOut, transIdle) =>
    transitionState === TransitionState.Show
      ? transIn
      : transitionState === TransitionState.Hide
      ? transOut
      : transIdle;

  const flipTransitionClass = selectTransitionClass(
    "animated flipInX fast",
    "animated flipOutX fast"
  );
  const slideTransitionClass = selectTransitionClass(
    "animated fadeInRight faser",
    "animated fadeOutLeft faster"
  );
  const fadeTransitionClass = selectTransitionClass(
    "animated fadeIn faster",
    "animated fadeOut faster"
  );
  const bounceTransitionClass = selectTransitionClass(
    "animated fadeInUp faster",
    "animated fadeOutUp faster"
  );

  const transitionDelay = transitionState === TransitionState.Show ? 0.4 : 0;
  const transitionDuration = 0.2;
  return (
    <View
      column
      backgroundImage={`linear-gradient(to top, #00000011, #00000033), ${background}`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      minHeight={split ? "100vh" : "100vh"}
      maxHeight="100vh"
      maxWidth="100vw"
      flexShrink={1}
      minWidth={split ? "auto" : "100vw"}
      flex={split ? 1 : 0}
      position="relative"
    >
      {title && (
        <View
          margin="1em"
          className={slideTransitionClass}
          style={{
            animationDelay: `${transitionDelay}s`,
            animationDuration: `${transitionDuration}s`
          }}
        >
          <Text fontSize=".8em" shadowSmall>
            {title}
          </Text>
        </View>
      )}
        <View
          backgroundColor="rgba(0,0,0,.85)"
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          width="100%"
          className={renderMenu && "animated zoomIn faster"}
          style={{
            animationDuration: '.2s'
          }}
        >
          {renderMenu && renderMenu()}
        </View>

      {toggleMenu && (
        <View
          position="absolute"
          top={0}
          right={0}
          className={fadeTransitionClass}
          style={{
            animationDelay: `${transitionDelay}s`,
            animationDuration: `${transitionDuration}s`
          }}
        >
          <Button transparent onClick={toggleMenu}>
            <img src={iconMenu} />
          </Button>
        </View>
      )}

      <View
        column
        marginLeft="1em"
        marginRight="1em"
        className={slideTransitionClass}
        style={{
          animationDelay: `${transitionDelay * 1.25}s`,
          animationDuration: `${transitionDuration}s`
        }}
      >
        {header && <Text fontSize="2em">{header}</Text>}
        {subheader && <Text>{subheader}</Text>}
      </View>

      <View
        column
        flexGrow={1}
        flexShrink={1}
        className={fadeTransitionClass}
        style={{ animationDelay: `${transitionDelay * 1.75}s` }}
      >
        {children}
      </View>
    </View>
  );
};

export const Text = ({
  children,
  big,
  huge,
  small,
  muted,
  bright,
  light,
  bold,
  color,
  shadowSmall,
  ...rest
}) => (
  <Box
    fontFamily="'Barlow Semi Condensed', sans-serif"
    fontWeight={bold ? "500" : light ? "300" : "400"}
    color={
      muted ? "#aaaa" : bright ? "rgba(224, 28, 120, .9)" : color || "#fff"
    }
    textShadow={shadowSmall ? theme.shadowSmall.boxShadow : "none"}
    fontSize={big ? "1.5rem" : huge ? "3rem" : small ? ".8em" : "1rem"}
    {...rest}
  >
    {children}
  </Box>
);

export const _Button = ({
  children,
  text,
  hover,
  bright,
  circle,
  shadow,
  transparent,
  primary,
  pressed,
  active,
  animate,
  onLongPress,
  ...rest
}) => {
  const pressTimeout = React.useRef(null);
  const bgDefault = hover ? "rgba(0,0,0,.2)" : "rgba(0,0,0,.1)";
  const bgBright = hover ? "rgba(224, 28, 120, .9)" : "rgba(224, 28, 120, .5)";
  const bgPrimary = hover ? "rgba(113, 214, 19, .9)" : "rgba(113, 214, 19, .5)";
  const bgTransparent = "none";
  const bg = bright
    ? bgBright
    : transparent
    ? bgTransparent
    : primary
    ? bgPrimary
    : bgDefault;
  const animClass = animate && active ? "animated tada fast" : "";
  return (
    <View
      padding="1em"
      margin=".1em"
      backgroundColor={bg}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      transition="background-color .0s"
      borderRadius={circle ? "50%" : "8px"}
      flexWrap="nowrap"
      userSelect="none"
      className={animClass}
      onMouseDown={() => {
        clearTimeout(pressTimeout.current)
        pressTimeout.current = setTimeout(onLongPress, 1000)
      }}
      onMouseUp={() => clearTimeout(pressTimeout.current)}
      {...rest}
    >
      {children || (
        <Text userSelect="none" whiteSpace="nowrap">
          {text}
        </Text>
      )}
    </View>
  );
};

export const Menu = props => {
  const options = [
    { label: "option 1" },
    { label: "option 2" },
    { label: "option 3" },
    { label: "option 4" },
    { label: "option 1" },
    { label: "option 2" },
    { label: "option 3" },
    { label: "option 4" },
    { label: "wowzers" }
  ];
  const { open, onSelect, ...rest } = props;

  return (
    <View
      {...rest}
      display={open ? "flex" : "none"}
      overflowX="auto"
      padding=".5em"
      column
    >
      {options.map(o => (
        <Button shadowSmall text={o.label} onClick={() => onSelect(o)}></Button>
      ))}
    </View>
  );
};
class TextInput extends Component {
  constructor(props) {
    super(props);
    this.inputElem = React.createRef();
  }
  componentDidMount() {
    this.inputElem.current && this.inputElem.current.focus();
  }
  render() {
    const { value, onChange, props } = this.props;
    return (
      <Box
        is="input"
        {...props}
        onChange={e => onChange({ value: e.target.value })}
        value={value || ""}
        padding="1em"
        borderRadius="8px"
        borderStyle="none"
        ref={this.inputElem}
      />
    );
  }
}

export class Prompt extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }
  render() {
    const { text, onCancel, onSubmit } = this.props;
    return (
      <View column width="100%">
        <Text marginBottom="1em">{text}</Text>
        <TextInput
          focus
          value={this.state.value}
          onChange={e => this.setState({ value: e.value })}
        />
        <View marginTop="1em">
          <Button text="Add" primary onClick={() => onSubmit(this.state)} />
          <Button text="Cancel" onClick={onCancel} />
        </View>
      </View>
    );
  }
}

export const Button = hover(_Button);

export { default as Box } from "ui-box";
