import React, { Component } from "react";
// import bg from './6131901459_11e752b640_o.jpg';
import bg from "./sun-mt-fuji-japan-landscape-407039.jpeg";

import * as storage from "./storage";
import { ScreenSet, View } from "../werksui";
import Analytics from "./screens/Analytics";
import Tracker from "./screens/Tracker";
import Settings from "./screens/Settings";
import * as Icon from "./components/Icons";

// utils
const slug = a => a.replace(/[\W_]+/g, "-").toLocaleLowerCase();
const sleep = async seconds => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000))


const initialState = {
  identity: null,
  access_token: null,
  activities: {
    reset: { name: "RESET" },
    test: { name: "TEST"},
    work: { name: "Work" },
    fam: { name: "Family" },
    side: { name: "Side Project" },
    shop: { name: "Shopping" },
    yard: { name: "Yard Work" },
    sleep: { name: "Sleep" },
    read: { name: "Read" }
  },
  starts: [],
  saving: false,
  loading: false,
  error: null,
  syncStatus: "sync disabled",
  toggles: []
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }
  startTracking = activity => {
    this.setState(
      prevState => ({
        starts: [...prevState.starts, { activity, time: new Date() }]
      }),
      this.sync
    );
  };
  toggleSetting = name => {
    const { toggles } = this.state;
    if (toggles.includes(name)) {
      this.setState(
        {toggles: toggles.filter(x => x != name)}
      )
    } else {
      this.setState({
        toggles: toggles.concat([name])
      })
    }
  }

  clearToken = () => this.setState({ access_token: null });
  mergeStarts = (local, remote) => {
    const seen = [];
    return [...local, ...remote]
      .filter(s => !seen.includes(+s.time) && seen.push(+s.time))
      .sort((a, b) => +a.time - +b.time);
  };
  mergeActivities = (local, remote) => {
    return { ...local, ...remote };
  };
  sync = async () => {
    const { access_token, activities, starts, syncStatus } = this.state;
    if (syncStatus == "syncing") {
      console.log('no sync')
      return;
    }
    console.log('syncing')
    this.setState({ saving: true, syncStatus: "syncing" });
    try {
      const remoteData = await storage.load(access_token);
      const saveData = {
        starts: this.mergeStarts(starts, remoteData.starts),
        activities: this.mergeActivities(activities, remoteData.activities)
      };
      await storage.save(access_token, saveData);
      this.setState(prev => ({
        starts: this.mergeStarts(prev.starts, saveData.starts),
        activities: this.mergeActivities(prev.activities, saveData.activities),
        saving: false,
        syncStatus: "synced"
      }));
    } catch (e) {
      console.log(e)
      this.setState({ saving: false, syncStatus: 'error' });
    }
  };
  addActivity = ({ value }) =>
    this.setState(
      prevState => ({
        activities: {
          ...prevState.activities,
          [slug(value)]: { name: value }
        },
        syncStatus: "pending"
      }),
      this.sync
    );

  componentDidMount = () => {
    const identity = storage.loadIdentity();
    this.sync();
    if (identity) {
      this.setState({ identity });
      storage.requestToken(identity.preferred_username, access_token =>
        this.setState({ access_token }, this.sync)
      );
    }
  };
  componentDidUpdate = prev => {
    console.log(prev)
  }

  render() {
    const size = (this.props.device && this.props.device.size) || "small";
    const activities = this.state.activities || {};
    const starts = this.state.starts.sort((a, b) => (a.time < b.time ? 1 : -1));
    const tracking = this.state.starts[0];
    const { loading, syncStatus } = this.state;
    const errorMessage = this.state.error && this.state.error.message;
    const trackerProps = {
      syncStatus,
      starts,
      tracking,
      activities,
      startTracking: this.startTracking,
      addActivity: this.addActivity
    };
    if (size === "small") {
      return (
        <ScreenSet
          nav={[<Icon.Track />, <Icon.Data />, <Icon.Config />]}
          icon={<Icon.Config />}
        >
          <Tracker {...trackerProps} />
          <Analytics activities={activities} starts={starts} />
          <Settings
            identity={this.state.identity}
            access_token={this.state.access_token}
            toggleSetting={this.toggleSetting}
            toggles={this.state.toggles}
            clearToken={this.clearToken}
            startTracking={this.startTracking}
          />
        </ScreenSet>
      );
    }
     

    return (
      <View>
        <Tracker {...trackerProps} split />
        <Analytics activities={activities} starts={starts} split />
        <Settings
          identity={this.state.identity}
          access_token={this.state.access_token}
          split
          toggles={this.state.toggles}
          toggleSetting={this.toggleSetting}
        />
      </View>
    );
  }
}

export default App;
